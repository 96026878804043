import { Text } from '@components/ui'
import { useEffect, useState } from 'react'
import styles from './Form.module.css'
import cn from 'classnames'
import Button from '@components/control/Button'
import { validate } from 'email-validator'
import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { sleep } from '@components/helper'
import { useRouter } from 'next/router'
import { getAdCookie } from '@lib/utils/cookies'
import * as dayjs from 'dayjs'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const Form = ({
  data,
  className,
  setSpinning,
  submitted,
  setSubmitted,
  requestError,
  setRequestError,
  setResult,
  controls,
  disabled = false,
  s: gs,
}) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const s = { ...styles, ...gs }
  // console.log('form disable:', disabled)

  const { locale } = useRouter()
  const variant = {
    rotate: [0, 360],
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: 'linear',
      repeatDelay: 0,
    },
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  // console.log('loading:', loading)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { register_source } = getAdCookie()
    if (!validate(email)) {
      setError(true)
    } else {
      const participatedEmails = localStorage
        .getItem('participatedEmails')
        ?.split(',')
      if (participatedEmails?.includes(email.toLowerCase())) {
        setRequestError(data.participatedError)
        return
      }
      setError(false)
      setRequestError(false)
      setSubmitted(true)

      controls.start(variant)
      setSpinning(true)

      const before = dayjs()
      const result = await fetcher({
        url: `/api/multipass/rainbowbridge/lottery/start?sub_brand_type=${data?.subDealsType}`,
        locale,
        action: 'lottery',
        needRecaptcha: true,
        method: 'post',
        body: {
          email,
          genre: data.dealsType,
          shopify_domain: I18N_STORE_DOMAIN[locale],
          register_source,
          action: 'LotteryStart',
        },
      })

      if (result?.errors) {
        setRequestError(result.message)
        controls.stop(variant)
        setSpinning(false)
        return
      }
      const copy = data.spinOrder?.find(
        (prize) => result?.data?.prize?.name === prize.id
      )
      setResult({
        ...copy,
        code: result?.data?.prize?.prize_detail?.code,
      })

      localStorage.setItem(
        'participatedEmails',
        participatedEmails + ',' + email.toLowerCase()
      )

      const after = dayjs()

      const diff = after.diff(before)
      // 至少转够两分钟
      if (after.diff(before) < 2000) {
        await sleep((2000 - diff) / 1000)
      }
      controls.stop(variant)
      const angle =
        (data.spinOrder?.findIndex(
          (prize) => result?.data?.prize?.name === prize.id
        ) ?? 1) * 60

      // console.log('angle:', angle)
      controls.start({
        rotate: [0, angle],
        transition: {
          duration: 1,
          ease: 'easeOut',
        },
      })
      // 暂停两秒看结果
      await sleep(2)
      setSpinning(false)
      // 订阅
      // await fetcher({
      //   locale,
      //   action: 'coming_subscribe_emails',
      //   url: '/api/multipass/subscribe/subscribe_emails',
      //   method: 'POST',
      //   body: {
      //     email,
      //     register_source,
      //     brand: 'anker',
      //     deals_type: data?.dealsType,
      //     sub_brand_type: data.subDealsType,
      //   },
      // })
      // 埋点
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'subscribe',
        eventAction: data.dealsType,
        eventLabel: data.start, //按钮文案
      })

      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'subscribe',
        event_parameters: {
          page_group: data.dealsType,
          position: data.start, //按钮文案
        },
      })
      window.fbq && window.fbq('track', 'CompleteRegistration')
    }
  }

  return (
    <form
      className={cn(s.innerCircle, className)}
      onSubmit={(e) => {
        handleSubmit(e)
      }}
    >
      <input
        className={s.input}
        placeholder={data.enterEmail || 'enter your email'}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      />
      <Button
        className={s.button}
        cns={s}
        disabled={disabled || loading}
        loading={loading}
      >
        {submitted ? data.participated : data.start || 'start'}
      </Button>
      {error && (
        <Text
          html={data.infoErrEmail}
          className={s.error}
          variant="paragraph"
        />
      )}
      {requestError && (
        <Text html={requestError} className={s.error} variant="paragraph" />
      )}
    </form>
  )
}

export default Form
