import { Picture, Text, PopLayout } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import s from './Pop.module.css'
import classNames from 'classnames'
import Form from './Form'
import { motion, useAnimationControls } from 'framer-motion'
import Result from './Result'
import cn from 'classnames'
import Cookies from 'js-cookie'

const Pop = ({ data, home, listing }) => {
  const [pop, setPop] = useState(false)
  const [popWindow, setPopWindow] = useState(false)
  const [shrunk, setShrunk] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const [result, setResult] = useState({})
  const [requestError, setRequestError] = useState()
  const controls = useAnimationControls()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      // 超过两屏弹出弹窗
      if (window.scrollY > window.innerHeight) {
        setPop(true)
        // console.log('Cookies', Cookies.get('pps spring sale pop'))
        if (home && !Cookies.get('pps spring sale pop')) {
          setPopWindow(true)
        }
      } else {
        setPop(false)
      }
    })
  }, [])

  useEffect(() => {
    if (!submitted) {
      // console.log('start:')
      controls.start({
        rotate: [0, 30, -30, 0],
        transition: {
          duration: 1.2,
          repeat: Infinity,
          repeatDelay: 0.8,
        },
      })
    }
  }, [controls, submitted, popWindow])

  return (
    <>
      {pop && !shrunk && !home && (
        <div
          className={classNames(s.popWrap, s.position, {
            [s.listingPosition]: listing,
          })}
        >
          <div className={s.popContainer}>
            <button aria-label="close pop" onClick={() => setShrunk(true)}>
              <XMarkIcon className="absolute right-[11px] top-[10px] stroke-[#777] w-[20px] h-[20px]" />
            </button>
            <Picture source={data.popImg} className={'w-[46px] h-[46px]'} />
            <div className="flex  flex-col ml-[12px] z-10">
              <Text html={data.popTitle} className={s.popTitle}></Text>
              <button
                className="text-[#196F00] mt-[6px] text-left font-semibold"
                onClick={() => setPopWindow(true)}
              >
                {data.popLinkLabel}
              </button>
            </div>
            <Picture
              source={data.popCover}
              className={'absolute bottom-0 left-0 w-full'}
            />
          </div>
        </div>
      )}
      {pop && shrunk && !home && (
        <button
          className={s.position}
          aria-label="start spinning"
          onClick={() => setPopWindow(true)}
        >
          <Picture source={data.popImg} className={'w-[46px] h-[46px]'} />
        </button>
      )}
      {popWindow && (
        <PopLayout className={cn('rounded-[10px] leading-[1.2] z-[9999]')}>
          <div className={s.popWindow}>
            <button
              aria-label="close pop"
              onClick={() => {
                setPopWindow(false)
                setShrunk(true)
                Cookies.set('pps spring sale pop', true)
              }}
            >
              <XMarkIcon className="absolute right-[35px] l:right-[18px] top-[18px] l:top-[18px] stroke-[#777] w-[20px] h-[20px]" />
            </button>
            <div className="flex flex-col items-start">
              {<Text html={data.popWindowTitle} className={s.popWindowTitle} />}
              {<Text html={data.popWindowNote} className={s.popWindowNote} />}
              <Form
                data={data}
                submitted={submitted}
                setSubmitted={setSubmitted}
                setSpinning={setSpinning}
                requestError={requestError}
                setRequestError={setRequestError}
                setResult={setResult}
                controls={controls}
                className="md:!hidden"
                disabled={submitted || spinning}
                s={s}
              />
              {<Text className={cn(s.policy)} html={data.policyText} />}
            </div>
            <div className={s.circleAndKv}>
              <div className={s.circle}>
                {(spinning || !submitted || requestError) && (
                  <motion.img
                    animate={controls}
                    src={data.circleImg}
                    className={s.circleImg}
                  />
                )}
                <Picture source={data.pinImg} className={s.pin} />
                {!spinning && submitted && !requestError && (
                  <Result data={data} result={result} home={home} s={s} />
                )}
                {(!submitted || (requestError && submitted)) && (
                  <Form
                    data={data}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    setSpinning={setSpinning}
                    requestError={requestError}
                    setRequestError={setRequestError}
                    setResult={setResult}
                    controls={controls}
                    disabled={submitted || spinning}
                    className="!hidden md:!flex"
                    s={s}
                  />
                )}
              </div>
              {(!submitted || spinning) && (
                <Picture source={data.popKv} className={cn(s.kv)} />
              )}
            </div>
          </div>
        </PopLayout>
      )}
    </>
  )
}

export default Pop
