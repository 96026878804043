import { useState } from 'react'
import clipboard from 'copy-to-clipboard'
import { twMerge as cn } from 'tailwind-merge'

// CODE: WS24OZ5XH0 Copy
const Code = ({ data, code, s = {}, className = '', handleGTM = null }) => {
  const [copied, setCopied] = useState(false)

  const onCodeCopy = (e, code) => {
    e.stopPropagation()
    setCopied(true)
    clipboard(code)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
    if (handleGTM) {
      handleGTM()
    }
  }

  return (
    <div className={cn('flex items-center font-medium', className)}>
      {code && (
        <>
          {data?.code && (
            <span
              className={cn(
                'codePrefix pr-[4px] text-[#999] md:pr-0.5',
                s.codePrefix
              )}
            >
              {data?.code}
            </span>
          )}
          <div className={cn('flex items-center', s.codeAndCopy)}>
            <span className={cn('code pr-[6px] text-[#999] md:pr-1', s.code)}>
              {code}
            </span>
            <button
              onClick={(e) => onCodeCopy(e, code)}
              className={cn(
                'copy text-anker-color underline',
                copied && 'no-underline',
                s.copy
              )}
            >
              {copied ? data?.copied : data?.copy}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default Code
