import { cn } from '@components/helper'

const PopLayout = ({ className, children, wrapClassName, theme }) => {
  return (
    <div
      className={cn(
        'fixed inset-0 z-[1000] flex h-[100vh] w-[100vw] items-center justify-center bg-black bg-opacity-60',
        wrapClassName
      )}
    >
      <div
        className={cn(
          'fixed z-10 mx-auto flex flex-col items-center justify-start overflow-scroll text-center [&::-webkit-scrollbar]:hidden',
          theme === 'dark' ? 'bg-[#000] bg-opacity-60' : 'bg-white',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default PopLayout
