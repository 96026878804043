import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

const SectionLayout = ({
  children,
  className = '',
  id = '',
  fullWidth = false, // 默认为 1280 宽度的组件(不包含背景)
  maxWidth = null,
  textColor = null,
  bgColor = null, // 背景永远为通屏
  paddingTop = '4rem',
  paddingBottom = '4rem', // 默认组件上下边距,
  responsive = [
    {
      breakpoint: 769,
      settings: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        fullWidth: true, //默认移动端通屏，如果配置了且设置为 false 后会设置 1rem 的边距
      },
    },
  ],
}) => {
  const cssId = uuidv4().slice(0, 5)
  const selector = id ? `#${id}` : `.section-layout-${cssId}`

  return (
    <section
      suppressHydrationWarning={true}
      id={id}
      className={cn(
        fullWidth ? 'w-full' : '',
        className,
        'relative',
        !id ? `section-layout-${cssId}` : ''
      )}
    >
      <style suppressHydrationWarning={true}>
        {`
      ${selector}{
        ${textColor ? `color: ${textColor};` : ''}
        ${bgColor ? `background: ${bgColor};` : ''}
        ${paddingTop ? `padding-top: ${paddingTop};` : ''}
        ${paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
      }

      ${
        fullWidth
          ? ''
          : `
            @media (max-width: ${maxWidth || 1280}px) {
              ${selector}{
              padding-left: 2rem;
              padding-right: 2rem;
              }
            }`
      }

      ${responsive
        ?.map((point) => {
          const { bgColor, paddingTop, paddingBottom, fullWidth, textColor } =
            point.settings || {}

          return `
              @media (max-width: ${point.breakpoint}px) {
                ${selector}{
                  ${textColor ? `color: ${textColor};` : ''}
                  ${bgColor ? `background: ${bgColor};` : ''}
                  ${paddingTop ? `padding-top: ${paddingTop};` : ''}
                  ${paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
                  padding-inline: ${
                    fullWidth === undefined || fullWidth ? '0' : '1rem'
                  };
                }
              }
              `
        })
        ?.join('')}
      `
          .replace(/\s+/g, ' ')
          .replace(/\n/g, ' ')}
      </style>
      {/* 奇怪的格式化 https://prettier.io/docs/en/rationale.html#jsx */}
      {fullWidth ? (
        <>{children}</>
      ) : (
        <div
          style={{ maxWidth: maxWidth ? maxWidth : '1280px' }}
          className={cn('relative mx-auto w-full max-w-base')}
        >
          {children}
        </div>
      )}
    </section>
  )
}

export default SectionLayout
