import Code from '@components/product/Code'
import { Picture, Text } from '@components/ui'
import { useRouter } from 'next/router'
import styles from './Result.module.css'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const Result = ({ data, result, home = false, s: gs }) => {
  const { locale } = useRouter()
  const s = { ...styles, ...gs }

  const handleGTM = () => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'copy',
      eventAction: 'solix',
      eventLabel: 'undefined',
      eventValue: 'undefined',
    })
  }

  return (
    <div className="absolute inset-0 z-20 w-full leading-[1.2]">
      <div className="relative h-full w-full">
        <div className="absolute inset-0 z-0 h-full w-full rounded-full bg-white "></div>
        <Picture source={data.resultBg} className="relative z-10" />
        <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
          <Text html={data.congratulations} className={s.congratulation} />
          <div className="relative">
            <Picture
              source={data.coupon}
              className={home ? s.homeCoupon : s.coupon}
            />
            <div className={s.container}>
              {result.label && <Text html={result.label} className={s.label} />}
              {result.description && (
                <Text html={result.description} className={s.description} />
              )}
              <Code
                data={data}
                code={result.code}
                className={s.code}
                handleGTM={handleGTM}
                s={{
                  codePrefix: 'text-white',
                  code: 'text-white ml-[2px]',
                  copy: s.copy,
                  codeAndCopy: s.codeAndCopy,
                }}
              />
              {data?.tips && (
                <Text
                  html={data.tips}
                  className={'mt-[5px] text-[12px] font-medium text-[#fff]'}
                />
              )}
              {home && (
                <a
                  href={`/${locale}${data.linkTo}`}
                  className="mt-[12px] rounded-[20px] bg-white py-[10px] px-[32px] font-semibold text-[#196F00] xl:py-[8px]"
                >
                  {data.linkLabel}
                </a>
              )}
            </div>
          </div>
          <Text html={data.note} className={s.note} />
        </div>
      </div>
    </div>
  )
}

export default Result
